<template>
  <div class="thanks bg-black">
    <Header />
    <section>
      <div class="gap-5"></div>
      <div class="gap-5 hideMobile"></div>
      <div class="container text-center p-5 mt-5 mb-5">
        <h1 class="yellow">
          THANK YOU!
        </h1>
        <p>
          YOUR PURCHASE WILL APPEAR ON <br />OPENSEA SHORTLY. THE CHARACTER
          <br />
          IS CURRENTLY HIDDEN AND WILL BE <br />
          REVEALED IN THE NEXT DAYS.
        </p>
      </div>
      <div class="gap-5"></div>
      <p>May the stories be with you.</p>
      <div class="gap-5"></div>
      <div class="gap-5"></div>
      <div class="gap-5"></div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "Thanks",
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
};
</script>
