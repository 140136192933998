<template>
  <div class="choose bg-black">
    <Header />
    <div style="min-height: 85vh">
      <section>
        <div class="gap-5"></div>
        <div class="gap-5 hideMobile"></div>
        <div class="container text-center p-5 mt-5 mb-5">
          <h1 class="yellow">CLAIM YOUR CHARACTER</h1>
          <br />
          <div v-if="!isLoading">
            <h3 class="mt-5">CHOOSE YOUR ROLES</h3>
            <h3 style="font-weight: normal !important" v-if="!isWhitelist">
              MAX {{ max }} CHARACTERS PER TRANSACTION
            </h3>
            <h3 style="font-weight: normal !important" v-if="isWhitelist">
              MAX {{ max }} CHARACTERS PER WHITELISTED ADDRESSES
            </h3>
          </div>

          <!-- NFTS RIMANENTI -->
          <div v-if="!isLoading" class="container available-nft">
            <h3 class="mt-5 text-center text-md-start text-lg-start">
              CLAIMED
            </h3>
            <div class="row mt-4">
              <div
                v-for="(character, index) in sell"
                v-bind:key="index"
                class="col-6 col-md-4 col-lg-3"
              >
                <p class="text-start">
                  <span>{{ character.sold }}/{{ character.max }} </span>
                  {{ character.display }}
                </p>
              </div>
              <img
                class="full_width mt-5 mb-5"
                src="/assets/img/bundle_claim.jpeg"
                alt=""
              />
            </div>
          </div>
          <!-- END NFTS RIMANENTI -->
        </div>
      </section>
      <section>
        <div class="d-flex justify-content-center">
          <div class="yellow-line mb-5"></div>
        </div>
        <div v-if="!isLoading" class="container choose_role">
          <div class="row">
            <div class="col-12">
              <div
                class="mb-3 d-flex align-items-center justify-content-center"
              >
                <!-- <h3 class="mx-auto text-center">
                {{ available.total }} CHARACTERS STILL AVAILABLE
              </h3> -->
              </div>
            </div>
          </div>
          <div class="container">
            <span v-for="(characters, index) in available" v-bind:key="index">
              <div v-if="sell[index] !== undefined" class="single_choose mb-3">
                <div>
                  <h3>{{ index }}</h3>
                </div>
                <div style="overflow: hidden; display: block; width: 100%">
                  <div
                    v-for="character in characters"
                    v-bind:key="character.tokenId"
                    style="width: 22%; float: left; margin: 5px 1.5%"
                  >
                    <div class="btn_choose">
                      <input
                        type="checkbox"
                        class="btn-check"
                        :id="character.tokenId"
                        :value="parseInt(character.tokenId)"
                        autocomplete="off"
                        v-model="selected"
                      />
                      <label
                        class="btn btn-outline-warning"
                        :for="character.tokenId"
                        >{{
                          character.role.replace("The ", "").toUpperCase()
                        }}
                        ({{ character.tokenId }})
                        <!-- ({{
                    count
                  }}) --></label
                      >
                    </div>
                  </div>
                </div>
                <div>
                  <br />
                  <hr />
                  <br />
                </div>
              </div>
            </span>
          </div>
          <!-- PURCHASE BUTTON -->
          <div class="gap-5"></div>
          <hr />
          <div
            class="container"
            v-if="selected.length > 0 && selected.length <= max"
          >
            <h3 class="mx-auto text-center">
              <input
                type="checkbox"
                style="width: 30px; height: 30px; margin-right: 20px"
                value="true"
                v-model="termsAccepted"
              />
              I agree and accept the
              <a href="/assets/term_conditions.pdf">terms and conditions</a>
            </h3>
            <div class="gap-5"></div>
            <div v-if="!payment">
              <button
                v-if="!account && termsAccepted"
                type="button"
                style="margin: 0 10px"
                class="btn btn-yellow"
                v-on:click="connect('ethereum', 'buy')"
              >
                BUY WITH ETHEREUM
              </button>
              <button
                v-if="!account && termsAccepted"
                type="button"
                style="margin: 0 10px"
                class="btn btn-yellow"
                v-on:click="connect('polygon', 'buy')"
              >
                BUY WITH MATIC
              </button>
              <h3
                class="mx-auto text-center"
                v-if="account && termsAccepted && !signature"
              >
                PLEASE APPROVE THE SIGNING REQUEST ON METAMASK..
              </h3>
              <h3
                class="mx-auto text-center"
                v-if="account && termsAccepted && signature && !payment"
              >
                PLEASE WAIT WHILE ASKING PAYMENT ADDRESS..
              </h3>
            </div>
            <div v-if="payment">
              <h3 class="mx-auto text-center">
                PLEASE SEND {{ payment.payment.amount }}
                <span v-if="chosen_payment_network === 'ethereum'">ETH</span
                ><span v-if="chosen_payment_network === 'polygon'">MATIC</span
                ><br />to
                {{ payment.payment.address }}
              </h3>
              <br />
              <button
                type="button"
                style="margin: 0 10px"
                class="btn btn-yellow"
                v-on:click="pay"
                v-if="!isPaying"
              >
                PAY WITH METAMASK
              </button>
              <h3 class="mx-auto text-center" v-if="isPaying && !pending">
                WAITING FOR TRANSACTION..
              </h3>
              <h3 class="mx-auto text-center" v-if="isPaying && pending">
                WAITING FOR CONFIRMATION AT HASH:<br />{{ pending }}
              </h3>
            </div>
          </div>
          <div class="row" v-if="selected.length === 0">
            <div class="col-12 col-md-12 col-lg-12">
              <div
                class="mb-3 d-flex align-items-center justify-content-center"
              >
                <h3 class="mx-auto text-center">CHOOSE AT LEAST 1 CHARACTER</h3>
              </div>
            </div>
          </div>
          <div class="row" v-if="selected.length > max">
            <div class="col-12 col-md-12 col-lg-12">
              <div
                class="mb-3 d-flex align-items-center justify-content-center"
              >
                <h3 class="mx-auto text-center">
                  YOU CAN BUY UP TO {{ max }} CHARACTERS
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isLoading">
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12">
              <div
                class="mb-3 d-flex align-items-center justify-content-center"
              >
                <h3 class="mx-auto text-center">
                  <br />
                  LOADING AVAILABLE CHARACTERS, PLEASE WAIT..
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <div class="yellow-line mt-5"></div>
        </div>
      </section>
      <div class="gap-5"></div>
      <div class="gap-5"></div>
    </div>
    <!-- Button trigger modal -->

    <!-- Modal -->

    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
const axios = require("axios");
import Web3 from "web3";
import Web3Modal, { isMobile } from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

export default {
  name: "Choose",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      axios: axios,
      gtag: window.gtag,
      polygon_network: 137,
      ethereum_network: 1,
      account: "",
      web3: {},
      isWhitelist: true,
      isLoading: true,
      termsAccepted: false,
      endWhitelist: 1641578400000,
      available: [],
      selected: [],
      apiUrl: process.env.VUE_APP_API_URL,
      payment: "",
      chosen_payment_network: "",
      signature: "",
      pending: "",
      box: "one",
      isPaying: false,
      max: 10,
      sell: {
        "The Actor": {
          display: "ACTORS",
          max: 12,
          sold: 0,
        },
        "The Airplane Pilot": {
          display: "PILOTS",
          max: 11,
          sold: 0,
        },
        "The Champ": {
          display: "CHAMPS",
          max: 14,
          sold: 0,
        },
        "The Coder": {
          display: "CODERS",
          max: 10,
          sold: 0,
        },
        "The Castaway": {
          display: "CASTAWAYS",
          max: 32,
          sold: 0,
        },
        "The Emperor": {
          display: "EMPERORS",
          max: 40,
          sold: 0,
        },
        "The Fighter": {
          display: "FIGHTERS",
          max: 27,
          sold: 0,
        },
        "The Gangster": {
          display: "GANGSTERS",
          max: 13,
          sold: 0,
        },
        "The Gentleman": {
          display: "GENTLEMEN",
          max: 13,
          sold: 0,
        },
        "The Hero": {
          display: "HEROES",
          max: 31,
          sold: 0,
        },
        "The Knight": {
          display: "KNIGHTS",
          max: 15,
          sold: 0,
        },
        "The Military": {
          display: "MILITARIES",
          max: 22,
          sold: 0,
        },
        "The Musician": {
          display: "MUSICIANS",
          max: 15,
          sold: 0,
        },
        "The Nerd": {
          display: "NERDS",
          max: 7,
          sold: 0,
        },
        "The Rapper": {
          display: "RAPPERS",
          max: 7,
          sold: 0,
        },
        "The Rebel": {
          display: "REBELS",
          max: 17,
          sold: 0,
        },
        "The Spy": {
          display: "SPIES",
          max: 18,
          sold: 0,
        },
        "The Thief": {
          display: "THIEVES",
          max: 25,
          sold: 0,
        },
        "The Inventor": {
          display: "INVENTORS",
          max: 20,
          sold: 0,
        },
        "The Adventurer": {
          display: "ADVENTURERS",
          max: 25,
          sold: 0,
        },
        "The Time Traveler": {
          display: "TIMETRAVELERS",
          max: 25,
          sold: 0,
        },
      },
    };
  },
  methods: {
    async connect(network, action) {
      const app = this;
      let providerOptions = {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            infuraId: "57d9ea9ca92a4449933c2b7d7145187d",
          },
        },
      };
      // Instantiating Web3Modal
      const web3Modal = new Web3Modal({
        cacheProvider: true,
        providerOptions: providerOptions,
      });
      const provider = await web3Modal.connect();
      app.web3 = await new Web3(provider);
      // Checking if networkId matches
      const netId = await app.web3.eth.net.getId();
      let selected_network = app.ethereum_network;
      if (network === "polygon") {
        selected_network = app.polygon_network;
      }
      app.chosen_payment_network = network;
      if (netId !== selected_network) {
        alert("Switch to correct network!");
      } else {
        const accounts = await app.web3.eth.getAccounts();
        if (accounts.length > 0) {
          app.account = accounts[0];
          console.log("Found account: " + app.account);
          if (action !== undefined && action === "buy") {
            app.buy();
          }
        } else {
          alert("Please allow at least one address!");
        }
      }
    },
    async buy() {
      const app = this;
      try {
        const message =
          "I want to buy following characters from Impssbl Corp:\n\n" +
          app.selected.join(",");
        const signed = await app.web3.eth.personal.sign(message, app.account);
        app.signature = signed;
        console.log("Asking payment..");
        const payment = await app.axios.post(app.apiUrl + "/buy-public", {
          method: app.chosen_payment_network,
          address: app.account,
          signature: app.signature,
          challenge: message,
          tokenIds: app.selected,
          box: "one",
        });
        if (payment.data.error !== undefined && payment.data.error === false) {
          app.payment = payment.data;
        } else {
          app.account = "";
          app.signature = "";
          alert(payment.data.message);
        }
      } catch (e) {
        alert(e.message);
        app.account = "";
      }
    },
    async pay() {
      const app = this;
      try {
        app.isPaying = true;
        const result = await app.web3.eth
          .sendTransaction({
            from: app.account,
            to: app.payment.payment.address,
            value: app.web3.utils.toWei(
              app.payment.payment.amount.toString(),
              "ether"
            ),
          })
          .on("transactionHash", (pending) => {
            app.pending = pending;
            app.gtag("event", "conversion", {
              send_to: "AW-10837375569/Gh4YCKCFv48DENH01K8o",
              transaction_id: pending,
            });
          });
        alert("Your purchase was successful!");
        window.location = "/thank-you";
      } catch (e) {
        app.isPaying = false;
        alert(e.message);
      }
    },
  },
  async mounted() {
    const app = this;
    let sold = await app.axios.get(app.apiUrl + "/nfts/sold/" + app.box);
    let counters = await app.axios.get(app.apiUrl + "/nfts/counters");
    let specific = await app.axios.get(app.apiUrl + "/nfts/available");
    if (new Date().getTime() > app.endWhitelist) {
      app.isWhitelist = false;
      app.max = 10;
    } else {
      app.max = 3;
    }
    app.available = {};
    for (let j in app.sell) {
      if (sold.data[j] !== undefined) {
        app.sell[j].sold = sold.data[j];
      }
      let toAdd = app.sell[j].max - app.sell[j].sold;
      if (
        counters.data.roles[j] !== undefined &&
        counters.data.roles[j] < toAdd
      ) {
        let delta = toAdd - counters.data.roles[j];
        app.sell[j].sold += delta;
        toAdd = app.sell[j].max - app.sell[j].sold;
        console.log("Found less than requested: " + j + " delta is " + delta);
      }
      for (let i = 0; i < toAdd; i++) {
        if (specific.data[j] !== undefined) {
          if (app.available[j] === undefined) {
            app.available[j] = [];
          }
          if (specific.data[j][i] !== undefined) {
            app.available[j].push(specific.data[j][i]);
          }
        }
      }
    }
    app.isLoading = false;
  },
};
</script>
<style scoped>
.btn.btn-yellow {
  margin: 15px !important;
}
.choose_role .btn {
  padding: 10px 30px;
}
.iubenda-tp-btn {
  display: none !important;
  opacity: 0 !important;
  visibility: hidden !important;
}
</style>
