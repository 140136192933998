<template>
  <div class="buy bg-black">
    <Header />
    <section>
      <div class="gap-5"></div>
      <div class="gap-5 hideMobile"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-11">
            <div class="container p-5 mt-5 mb-4">
              <div class="row justify-content-center">
                <div class="col-12 col-md-7 col-lg-7">
                  <h1 class="yellow text-center me-5" style="font-size: 3rem">
                    THE OFFICIAL LAUNCH<br />
                    <span style="color: white !important"
                      >BOX I: THE OPENING</span
                    >
                  </h1>
                </div>
              </div>
            </div>
            <div class="container text-start">
              <h3 class="my-4 text-start">RULES</h3>
              <p class="text-start" style="font-size: 1.8rem">
                THIS BOX CONTAINS 399 CHARACTERS FROM 21 DIFFERENT ROLES. THE
                SALE IS OPEN FROM JANUARY 7 AT 12 PM EST TO JANUARY 31 AT 12 PM
                EST. WE’VE SET AN UPPER LIMIT OF 10 CHARACTERS PER TRANSACTION
                (3 IN THE WHITELISTING). THE PRICE IS 0.08 ETH OR MATIC
                EQUIVALENT.<br /><br /><b>“BATTLE ROYALE”</b><br /><br />SOME
                ROLES ARE EXCLUSIVE TO THIS BOX AND CAN ONLY BE CLAIMED AS PART
                OF IT. OTHERS WILL APPEAR IN FUTURE BOXES TOO. IF A ROLE GOES
                UNCLAIMED (I.E., IT SELLS 0), THAT ROLE DISAPPEARS FROM THE MAIN
                SAGA AND THE UNSOLD NFTS FROM THE ROLE ARE BURNT (ALSO FROM
                FUTURE BOXES).
              </p>
            </div>
            <div class="mt-5 mb-5"></div>
            <!-- BUTTONS -->
            <div
              class="
                container
                d-flex
                flex-column flex-md-row flex-lg-row
                justify-content-center
                mb-5
              "
            >
              <a class="mx-5" href="/choose-a-role">
                <div class="btn-yellow">BUY</div>
              </a>
            </div>
            <div class="gap-5"></div>
            <div class="container text-start">
              <h3 class="text-start">THE BOX</h3>
              <p class="text-start">
                THE BOX CONTAINS 399 CHARACTERS DIVIDED IN 18 ROLES. THE STORIES
                HAVE DIFFERENT RARITY AND COULD BE: COMMON, RARE, LEGENDARY OR
                UNIQUE.
              </p>
              <div class="sl_box mt-5">
                <img src="/assets/img/sl_box.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="gap-5"></div>
    <section class="mb-mobile">
      <div class="container mt-5">
        <h3 class="text-start">THE ROLES</h3>
        <p class="text-start">
          A SNAPSHOT OF THE ROLES. PLEASE NOTE THAT EVERY ROLE HAS A COMBINATION
          OF DIFFERENT BODIES (MALE, FEMALE, ALIEN, ROBOT, ZOMBIE, APE...)
        </p>
        <div class="mt-5">
          <img src="/assets/img/bundle_characters.png" alt="" width="100%" />
        </div>
        <div class="gap-5"></div>
        <!-- BUTTONS -->
        <div
          class="
            container
            d-flex
            flex-column flex-md-row flex-lg-row
            justify-content-center
            mt-5
          "
        >
          <a class="mx-5" href="/choose-a-role">
            <div class="btn-yellow">BUY</div>
          </a>
        </div>
        <div class="gap-5"></div>
      </div>
    </section>
    <div class="gap-5"></div>
    <div class="gap-5"></div>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "Buy",
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.iubenda-tp-btn {
  display: none !important;
}
</style>
